<template>
  <div class="pagePadding">
    <div class="pageTop">
      <Form inline class="formMarginBtm20 clearfix">
        <FormItem>
          <span>客户名称：</span>
          <Select class="iviewIptWidth250 marginRight60" filterable v-model="supplierIndex">
            <Option v-for="(item, index) in clientNameList" :value="item.customer_id" :key="item.customer_id + item.customer_type">{{ item.customer_name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span style="margin-left: 18px;">经手人：</span>
          <Select class="iviewIptWidth250 marginRight60" v-model="queryFrom.handled_by" clearable filterable>
            <Option v-for="item in handledByList" :value="item.id" :key="item.id">{{ item.user_name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span>产品名称：</span>
          <Select class="iviewIptWidth250 marginRight60" @on-change="productChange" v-model="queryFrom.product_id" clearable filterable>
            <Option v-for="item in productList" :value="item.product_id" :key="item.product_id">{{ item.product_name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span>规格型号/SKU：</span>
          <Select class="iviewIptWidth250" @on-query-change="serachChange" multiple filterable clearable v-model="queryFrom.product_code_array" :max-tag-count="queryFrom.product_code_array.length == 1 ? 1 : 0">
            <Option v-if="!Loading" v-for="item in modelList" :value="item.code_id" :key="item.code_id + item.model_name" :label="item.model_name + '-' + item.item_number">
              <span>{{ item.model_name }}</span>
              <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
            </Option>
            <!-- <div v-if="Loading" class="centerFlex"> -->
            <Spin v-if="Loading">
              <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
              <div>Loading</div>
            </Spin>
            <!-- </div> -->
          </Select>
          <!--  -->
        </FormItem>
        <FormItem class="po-create-number marginRight0" :label-width="10">
          <span class="pageBtn finger btnSure" @click="queryProductLookup">查询</span>
        </FormItem>
      </Form>
      <Table :columns="listColumns" :data="meterList" border no-data-text="暂无产品" :loading="Flag">
        <template slot-scope="{ row, index }" slot="price">
          <div class="danjia">
            <span class="fl">¥ </span>
            <!--            <i-input type="number"  maxlength="8" class="fl" :value="row.sell_quote" placeholder="请输入" style="width: 100px" @on-change="changeIpt($event, 'sell_quote', index, row)"></i-input>-->
            <InputNumber :max="999999999.99" :min="0" :precision="2" v-model="row.sell_quote" :active-change="false" style="width: 90%;" @on-change="changeIpt($event, 'sell_quote', index, row)"></InputNumber>
          </div>
        </template>
      </Table>
      <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="meterList.length == 0 && !Flag">
        <img style="margin-top: 100px;" src="../../assets/images/tableNoData.png" />
      </div>
      <div class="tipsDiv">
        <div class="tipsFl">备注</div>
        <div class="tipsFr">
          <i-input type="textarea" maxlength="100" show-word-limit v-model="postFrom.remark" placeholder="请输入"></i-input>
        </div>
      </div>
    </div>
    <div class="pageBtm clearfix">
      <span class="pageBtn finger btnSure marginLeft20 fr" @click="save">提交</span>
      <span class="pageBtn finger btnReset fr" @click="back">返回</span>
    </div>
    <!--    弹窗选择数据-->
    <Modal v-model="chooseStatus" label-position="left" width="1200" :footer-hide="true" title="产品列表">
      <div v-if="chooseStatus">
        <Table :loading="resultLoaging" :columns="chooseColumns" :data="chooseList" border @on-selection-change="selectChange" height="500"></Table>
        <div class="clearfix" style="margin-top: 20px;">
          <span class="pageBtn finger btnSure fr" @click="sureChoose">确定</span>
        </div>
      </div>
    </Modal>
    <!--    删除产品-->
    <tips-component showModal="delete" v-if="deleteStatus" @cancleBtn="deleteStatus = false" @sureBrn="sureDelete"></tips-component>
    <!--    提交-->
    <tips-component showModal="post" v-if="postStatus" @cancleBtn="postStatus = false" @sureBrn="surePost"></tips-component>
  </div>
</template>

<script>
import TipsComponent from '@/components/tipsComponent'
export default {
  name: 'quotationAdd',
  components: {
    TipsComponent,
  },
  data() {
    return {
      Flag: false,
      loadingText: '879789',
      Loading: false,
      supplierIndex: -1,
      supplierId: '',
      postStatus: false,
      deleteStatus: false,
      queryFrom: {
        handled_by: '',
        product_id: '',
        product_code_array: [],
      },
      postFrom: {
        remark: '',
        handled_by: '',
        customer_info: {},
        product_info: [],
      },
      handledByList: [], // 经手人
      supplierList: [], // 客户名称
      productList: [],
      modelList: [], // sku列表
      storeList: [],
      meterList: [], // 已选产品列表
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '产品名称',
          key: 'name',
          align: 'center',
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 115,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          width: 115,
        },
        {
          title: '厂家',
          key: 'factory_name',
          align: 'center',
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 85,
        },
        {
          title: '包装规格',
          key: 'packing_size',
          align: 'center',
          width: 114,
        },
        {
          title: '销售报价(含税)',
          slot: 'price',
          align: 'center',
          width: 170,
        },
        {
          title: '操作',
          align: 'center',
          width: 100,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.deleteRow(param.index)
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      chooseStatus: false,
      chooseList: [],
      chooseColumns: [
        {
          type: 'selection',
          align: 'center',
          width: 55,
        },
        {
          title: '产品名称',
          key: 'name',
          align: 'center',
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 150,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          width: 120,
        },
        {
          title: '厂家名称',
          key: 'factory_name',
          align: 'center',
        },
      ],
      hadSelectList: [], // 弹窗已选数据
      clickIndex: Number, // 暂存点击删除数据下标
      clientNameList: [],
      resultLoaging: false,
      trime: null,
    }
  },
  mounted() {
    this.querySpecialUser()
    this.queryList()
    this.queryProduct()
    this.querySupplierSelect()
    if (this.$route.query.id) {
      this.saleQuotationOperate()
    } else {
      this.Flag = false
    }
  },
  methods: {
    saleQuotationOperate() {
      this.Flag = true
      this.$http.get(this.$api.saleQuotationOperate, { quotation_code: this.$route.query.id }, true).then(res => {
        this.supplierIndex = res.data.customer_id
        this.queryFrom.handled_by = res.data.handled_by
        this.postFrom.remark = res.data.remark
        this.meterList = res.data.quotation_detail
        this.meterList.forEach((item, index) => {
          item.name = res.data.quotation_detail[index].product_name
          item.licence_code = res.data.quotation_detail[index].product_licence_code
          item.item_number = res.data.quotation_detail[index].product_item_number
          item.unit = res.data.quotation_detail[index].product_unit
          item.packing_size = res.data.quotation_detail[index].product_packing_size
          item.model_name = res.data.quotation_detail[index].product_model_name
        })
        this.Flag = false
      })
    },
    // 规格信号模糊搜索
    serachChange(e) {
      this.Loading = true
      this.$http.get(this.$api.productShow, { product_id: this.queryFrom.product_id, search_type: '1', only_model: '1', model_keyword: e }, false).then(res => {
        this.modelList = res.data
        this.Loading = false
      })
    },
    // 查询客户名称列表
    querySupplierSelect() {
      this.$http.fetch(this.$api.customerList, false).then(res => {
        this.clientNameList = res.data
      })
    },
    back() {
      this.$router.go(-1)
    },
    // 查询产品
    queryProduct(id = null) {
      if (id) {
        this.$http.get(this.$api.productShow, { product_id: id, search_type: '1' }, false).then(res => {
          // if (!id) {
          //   this.productList = res.data
          // } else {
          this.modelList = res.data
          // }
        })
      } else {
        // this.Loading = true
        // this.$http.get(this.$api.productShow, { product_id: id, search_type: '1', only_model: '1' }, false).then(res => {
        //   // if (!id) {
        //   //   this.productList = res.data
        //   // } else {
        //   this.Loading = false
        //   this.modelList = res.data
        //   // }
        // })
        this.$http.get(this.$api.productShow, { product_id: id, search_type: '1' }, false).then(res => {
          // if (!id) {
          //   this.productList = res.data
          // } else {
          this.productList = res.data
          // }
        })
      }
    },
    // 查询经手人和审核人
    querySpecialUser() {
      this.$http.get(this.$api.publicSpecialUser, { user_type: 1 }, true).then(res => {
        this.handledByList = res.data
      })
    },
    // 查询仓库列表
    queryList() {
      this.isLoad = true
      let data = {
        warehouse_name: '',
        page: 1,
        rows: 1000,
      }
      this.$http.get(this.$api.permissionWarehouseList, data, true).then(res => {
        this.storeList = res.data
      })
    },
    // 确认删除
    sureDelete() {
      this.meterList.splice(this.clickIndex, 1)
      this.deleteStatus = false
      this.$Message.success('删除成功')
      console.log(this.meterList, '删除')
    },
    // 点击删除按钮
    deleteRow(index) {
      this.clickIndex = index
      this.deleteStatus = true
    },
    // 确认选择 关闭弹窗
    sureChoose() {
      if (this.hadSelectList.length == 0) {
        this.chooseStatus = false
        return
      }
      this.chooseStatus = false
      if (this.meterList.length > 0) {
        let select = this.meterList.map(item => item.product_id + item.code_id)
        this.hadSelectList = this.hadSelectList.filter(i => {
          return !select.includes(i.product_id + i.code_id)
        })
        let arr = [...this.meterList, ...this.hadSelectList]
        this.meterList = JSON.parse(JSON.stringify(arr))
      } else {
        this.meterList = JSON.parse(JSON.stringify(this.hadSelectList))
      }
      console.log(this.meterList)
    },
    // 选择数据发生改变时
    selectChange(list) {
      this.hadSelectList = list
    },
    // 点击查询按钮 查询弹窗
    queryProductLookup() {
      if (!this.queryFrom.product_id || !this.queryFrom.product_code_array) {
        this.$Message.warning('请选择产品名称或者规格型号')
        return
      }
      let query = {
        product_id: this.queryFrom.product_id,
        product_code_array: this.queryFrom.product_code_array ? this.queryFrom.product_code_array.join(',') : '',
        search_type: '1',
      }
      this.chooseStatus = true
      this.resultLoaging = true
      this.loadingText = '数据加载中'
      this.$http.get(this.$api.productSearch, query, false).then(res => {
        this.resultLoaging = false
        if (res.data.length > 0) {
          this.hadSelectList = []
          this.chooseList = res.data
          for (let i = 0; i < this.chooseList.length; i++) {
            this.chooseList[i].sell_quote = this.chooseList[i].unit_price
          }
        } else {
          this.$Message.warning('查询暂无数据')
        }
      })
    },
    // 产品名称改变
    productChange(e) {
      this.queryFrom.product_code_array = []
      // if (!e) return
      this.modelList = []
      this.queryProduct(e)
    },
    // 输入框改变
    changeIpt(e, name, index, row) {
      this.$set(this.meterList[index], name, e * 1)
    },
    // 保存
    save() {
      let obj = {}
      if (this.supplierIndex < 0) {
        this.$Message.warning('请选择客户名称')
        return
      }
      if (!this.queryFrom.handled_by) {
        this.$Message.warning('请选择经手人')
        return
      }
      if (this.meterList.length == 0) {
        this.$Message.warning('请至少添加一个产品')
        return
      }
      for (let i = 0; i < this.meterList.length; i++) {
        this.meterList[i].product_name = this.meterList[i].name
        this.meterList[i].product_licence_code = this.meterList[i].licence_code
        this.meterList[i].product_item_number = this.meterList[i].item_number
        this.meterList[i].product_unit = this.meterList[i].unit
        this.meterList[i].product_packing_size = this.meterList[i].packing_size
        this.meterList[i].product_model_name = this.meterList[i].model_name
        this.meterList[i].product_code_id = this.meterList[i].code_id || this.meterList[i].product_code_id
        if (!this.meterList[i].sell_quote) {
          this.$Message.warning(`请完善第${i + 1}组产品的销售报价`)
          return
        }
      }
      this.postFrom.product_info = JSON.parse(JSON.stringify(this.meterList))
      this.postFrom.handled_by = this.queryFrom.handled_by
      this.postFrom.customer_info = this.clientNameList[this.supplierIndex - 1]
      this.postStatus = true
      // this.$http.post(this.$api.saleQuotation, this.postFrom, true).then(res => {
      //   this.$Message.success('提交成功')
      //   this.$router.go(-1)
      // })
      sessionStorage.setItem('updataCache', '0')
    },
    // 最终确认提交
    surePost() {
      this.postStatus = false
      if (this.$route.query.id) {
        this.postFrom.quotation_code = this.$route.query.id
        this.$http.put(this.$api.saleQuotation, this.postFrom, true).then(res => {
          this.$Message.success('提交成功')
          this.$router.go(-1)
        })
      } else {
        this.$http.post(this.$api.saleQuotation, this.postFrom, true).then(res => {
          this.$Message.success('提交成功')
          this.$router.go(-1)
        })
      }
    },
  },
}
</script>

<style scoped lang="less">
.pagePadding {
  display: flex;
  flex-direction: column;
  .pageTop {
    flex: 1;
    overflow-y: auto;
  }
  .pageBtm {
    float: right;
    padding-top: 25px;
  }
}
.marginRight60 {
  margin-right: 60px;
}
.marginRight50 {
  margin-right: 55px;
}
.danjia {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.iviewIptWidth270 {
  width: 290px !important;
}
.resultoaging {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 550px;
}
.centerFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tipsDiv {
  margin-top: 20px;
  border: 1px solid #e8eaec;
  height: 230px;
  display: flex;
  .tipsFl {
    width: 210px;
    line-height: 230px;
    padding-left: 33px;
    border-right: 1px solid #e8eaec;
    background: #f8f8f9;
    color: #525b6d;
    font-size: 14px;
  }
  /deep/ .tipsFr {
    flex: 1;
    display: flex;
    padding: 8px 15px 8px 23px;
    .ivu-input-wrapper {
      height: 100% !important;
    }
    .ivu-input {
      height: 100% !important;
    }
  }
}
</style>
